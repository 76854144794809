import React from 'react'

import SEO from 'src/components/seo'
import { Trans, withTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import { IN_UK } from '../utils/constants'

import {
  Container,
  Column,
  Line,
  CurrentPage,
  PageLink,
  TosSubtitle,
  Text,
  ALink,
  SubHeader,
  SubHeaderIndex,
  Highlight,
  PageTitle,
  HighlightList,
  OrderedList,
  TosSubHeader,
} from '../templates/ShopifyPage/styles'

const Tos = () => (
  <>
    <SEO
      title="Terms of Service"
      description="The Skylight Frame (Glimpse LLC) legal Terms of Service."
      path="/tos/"
    />
    <Container>
      <Column>
        <div>
          <Line />
          <PageLink to="/privacy-policy/">
            <Trans>Privacy Policy</Trans>
          </PageLink>
          <Line />
          <CurrentPage to="/tos/">
            <Trans>Terms of Service</Trans>
          </CurrentPage>
          <Line />
          {IN_UK && (
            <>
              <PageLink to="/psti-compliance/">
                <Trans>PSTI Statement of Compliance</Trans>
              </PageLink>
              <Line />
            </>
          )}
        </div>
      </Column>
      <Column>
        <PageTitle>
          <Trans>Terms of Service</Trans>
        </PageTitle>
        <TosSubtitle>
          <Trans i18nKey="auto_translate_tos" />
          <br />
          <br />
          <Trans>
            Glimpse LLC (doing business as "Skylight"), a Delaware Limited Liability Company
            ("Skylight", "we" or "us")
          </Trans>
          <br />
          <Trans>Last updated: May 14th, 2020. Effective as of: May 14th, 2020</Trans>
        </TosSubtitle>
        <SubHeader color="#f66951">
          <Trans>Please Read The Following Terms And Conditions Carefully</Trans>
        </SubHeader>
        <Text>
          <Trans>
            THE TERMS AND CONDITIONS SET FORTH BELOW "TERMS" GOVERN YOUR USE OF THE WEBSITE LOCATED
            AT
          </Trans>{' '}
          <ALink href="https://www.skylightframe.com">WWW.SKYLIGHTFRAME.COM</ALink>{' '}
          <Trans>
            THE "SITE" AND THE SKYLIGHT FRAME PRODUCT (COLLECTIVELY, THE "SERVICES"). THESE TERMS
            ARE A LEGAL CONTRACT BETWEEN YOU AND SKYLIGHT AND GOVERN YOUR ACCESS TO, AND USE OF, THE
            SERVICES. IF YOU DO NOT AGREE WITH ANY OF THESE TERMS, DO NOT ACCESS OR OTHERWISE USE
            THE SERVICES OR ANY INFORMATION CONTAINED ON THIS SITE. YOUR USE OF THIS SITE AND/OR THE
            SERVICES WILL BE DEEMED TO BE YOUR AGREEMENT TO ABIDE BY EACH OF THE TERMS SET FORTH
            BELOW. SKYLIGHT MAY MAKE CHANGES TO THE CONTENT AND THE SERVICES OFFERED ON THIS SITE AT
            ANY TIME. SKYLIGHT MAY CHANGE THESE TERMS AT ANY TIME BY POSTING UPDATED TERMS OF USE ON
            THE SITE(S) AND BY SENDING REGISTERED USERS AN EMAIL NOTICE OF THE CHANGES. IF ANY
            MODIFICATION IS UNACCEPTABLE TO YOU, YOU WILL CEASE USING THE SITE AND SERVICES. IF YOU
            DO NOT CEASE USING THE SITE AND SERVICES, YOU WILL BE DEEMED TO HAVE ACCEPTED THE
            CHANGE.
          </Trans>
        </Text>
        <Text>
          <Trans>
            YOU REPRESENT, ACKNOWLEDGE AND AGREE THAT YOU ARE AT LEAST 18 YEARS OF AGE. YOU
            REPRESENT TO SKYLIGHT THAT YOU ARE LAWFULLY ABLE TO ENTER INTO CONTRACTS. IF YOU ARE
            ENTERING INTO THIS AGREEMENT FOR AN ENTITY, SUCH AS A COMPANY, MUNICIPALITY, OR
            GOVERNMENT AGENCY YOU WORK FOR, YOU REPRESENT TO SKYLIGHT THAT YOU HAVE LEGAL AUTHORITY
            TO BIND THAT ENTITY. IF YOU DO NOT HAVE AUTHORITY TO BIND SUCH ENTITY, DO NOT USE THE
            SERVICES. IF YOU DISAGREE WITH ANY OF THE TERMS BELOW, WE DO NOT GRANT YOU ANY LICENSE
            OR RIGHT TO USE THE SERVICES OR THE SITE.
          </Trans>
        </Text>
        <Text bold>
          <Trans>
            THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION PROVISION THAT, AS FURTHER SET FORTH IN
            SECTION 19 BELOW, REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE
            DISPUTES, RATHER THAN JURY TRIALS OR ANY OTHER COURT PROCEEDINGS, OR CLASS ACTIONS OR
            CLASS ARBITRATIONS OF ANY KIND.
          </Trans>
        </Text>
        <OrderedList>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Registration</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                When You register on the Site, You may be asked to create Your own profile. Your
                profile includes, but is not limited to, personal information such as Your user
                name, Your email and general information You wish to disclose about Yourself. Upon
                successful registration, You become a member of the Website (a "Member"), and for as
                long as You remain a Member, You agree to comply with the then applicable Terms.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>General Use Restrictions</Trans>
            </TosSubHeader>
          </SubHeaderIndex>
          <OrderedList>
            <HighlightList>
              <Highlight>
                <Trans>Materials</Trans>{' '}
              </Highlight>
              <Trans>
                Certain information, documents, materials and services provided on and through the
                Site(s), including content, logos, graphics and images (together, the "Materials")
                are made available to You by us and are our copyrighted and/or trademarked work.
                Materials do not include Content (as defined below). The terms specific to the
                Submissions are described below.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>License</Trans>{' '}
              </Highlight>
              <Trans>
                The Service is licensed (not sold) to You. Subject to your compliance with this
                Agreement, and solely for so long as you are permitted by Skylight to use the
                Service, Skylight grants You a limited, personal, non-exclusive and non-transferable
                license to use and to display and to make one copy of the Materials and to use the
                Materials on the Site(s) solely for Your personal use. Except for the license set
                forth in the preceding sentence above, You acknowledge and agree that You have no
                right to modify, edit, copy, reproduce, create derivative works of, reverse
                engineer, alter, enhance or in any way exploit any of the Materials in any manner.
                This limited license terminates automatically, without notice to You, if You breach
                any of these Terms. Upon termination of this limited license, You agree to
                immediately destroy any downloaded or printed Materials. Except as stated herein,
                You acknowledge that You have no right, title or interest in or to the Site(s), any
                Materials, or the Services.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Restrictions</Trans>{' '}
              </Highlight>
              <Trans>
                You are permitted to access and use the Services and Skylight reserves the right to
                audit such use. You will be responsible for ensuring the security and
                confidentiality of all log-on identifiers, including usernames and passwords,
                assigned to, or created by, You in order to access or use the Services (an "ID").
                You accept that You will be solely responsible for all access and use of the
                Services that occur under Your ID and will promptly notify Skylight upon becoming
                aware of any unauthorized access to or use of the Services. You will provide all
                reasonable assistance to Skylight to bring an end to such unauthorized access or
                use.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Use Limits</Trans>{' '}
              </Highlight>
              <Trans>
                You agree not to misuse the Skylight Services. For example, You must not, and must
                not attempt to, use the services to do the following things: (i) probe, scan, or
                test the vulnerability of any system or network; (ii) breach or otherwise circumvent
                any security or authentication measures; (iii) access, tamper with, or use
                non-public areas of the Service, shared areas of the Service You have not been
                invited to, Skylight (or our service providers&rsquo;) computer systems; (iv)
                interfere with or disrupt any user, host, or network, for example by sending a
                virus, overloading, flooding, spamming, or mail-bombing any part of the Services;
                (v) plant malware or otherwise use the Services to distribute malware; (vi) access
                or search the Services by any means other than our publicly supported interfaces
                (for example, "scraping"); (vii) send unsolicited communications, promotions or
                advertisements, or spam; (viii) send altered, deceptive or false source-identifying
                information, including "spoofing" or "phishing"; (ix) publish anything that is
                fraudulent, misleading, or infringes another&rsquo;s rights; (x) promote or
                advertise products or services other than Your own without appropriate
                authorization; (xi) impersonate or misrepresent Your affiliation with any person or
                entity; (xii) publish or share materials that are unlawfully pornographic or
                indecent, or that advocate bigotry, religious, racial or ethnic hatred; or (xiii)
                violate the law in any way, or to violate the privacy of others, or to defame
                others.
              </Trans>
            </HighlightList>
          </OrderedList>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Privacy Policy</Trans>
            </TosSubHeader>
          </SubHeaderIndex>
          <Text>
            <Trans>Your use of the Site(s) is governed by the Skylight Privacy Policy.</Trans>
          </Text>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Content</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                As between the parties, You will remain the owner of all rights (including all
                intellectual property rights) in all data, information or material provided or made
                available by You to Skylight through access to or use of the Services and will
                exclude Statistics ("Content"). You grant Skylight a perpetual, irrevocable,
                non-exclusive, worldwide and royalty-free license, to use, reproduce, distribute,
                publish and publicly display the Content and the right to modify, adapt, translate,
                create derivative works from and/or incorporate the same into other works in any
                form, media or technology (whether now known or later developed), solely and
                exclusively for the purpose of providing the Services to You and performing
                Skylight&rsquo;s obligations under this Agreement. This license includes the right
                for Skylight to assign and/or sub-license the same to any of its affiliates or
                service providers or sub-license to licensors, but only to the extent required by
                Skylight to provide the Services and perform its obligations under this Agreement.
                For the avoidance of doubt, all media you share with Skylight through the
                application and over its servers, and any derivative works, will only be shared with
                you and users to whom you allow access to your Skylight account; under no
                circumstances will any of your media be used for marketing or other purposes
                unrelated to your use of the Skylight service, without your express permission. The
                term Statistics means any anonymous data, and expressly excluding personally
                identifiable data, that Skylight derives through its Site and performance of the
                Services. For the avoidance of doubt, Skylight may use Statistics for the
                modification, enhancement, and improvement of the Services, and may use Statistics
                for the marketing or promotion of Skylight, the Services or any future product or
                service offering, provided any such use of Statistics cannot reasonably be
                identified as Your aggregate information alone. You agree You will not upload, post
                or otherwise make available on the Site(s) any material protected by copyright,
                trademark, or any other proprietary right without the express permission of the
                owner of such right, and the burden of determining whether any Content is protected
                by any such right is on You. You will be solely liable for any damage resulting from
                any infringement of copyrights, trademarks, proprietary rights, or any other harm
                resulting from any Content that You make. You represent and warrant that: (i) You
                own all Content posted by You on or through the Services or otherwise have the right
                to grant the licenses to Skylight set forth in this section; and (ii) the posting of
                Your Contents through the Services do not violate the privacy rights, publicity
                rights, trademark rights, copyrights, contract rights or any other rights of any
                person or entity. You agree to pay for all royalties, fees, damages and any other
                monies owing any person by reason of any Contents posted by You to or through the
                Services.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Services</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                The Services are primarily for the display of Your Content in digital form in
                Skylight products. Skylight reserves the right to make enhancements or other
                modifications to the Services from time to time, without materially detracting from
                their overall functionality.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Updates</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                Skylight may allow or require you to download updates or patches onto your Skylight
                product. Certain updates may be required to continue use of Skylight products and
                Services. You consent to Skylight automatically installing available updates to
                Skylight products and Services. Failure to install available updates may render
                Skylight products and/or Services unusable.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Suspension of Services</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                Skylight may temporarily suspend (in whole or part) access to the Services: (i) for
                the purpose of performing maintenance and/or of updating the Services (whether on a
                scheduled or unscheduled basis); (ii) due to a security threat or prejudicial use of
                the Services (perceived or actual); (iii) to comply with any applicable law or the
                requirement of any Internet or, if applicable, telecommunication networks service
                provider upon whom Skylight relies for the provision of the Services; or (iv) to
                address, remedy or prevent any breach of this Agreement by You; and to minimize any
                period of interruption and restore the Services as soon as reasonably possible,
                except where the cause of suspension arises from Your breach and You have failed to
                take the necessary steps to remedy the breach.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Third Party Content</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                Certain information and other content appearing in the Services may be the material
                of third party licensors, users or suppliers to Skylight ("Third Party Content").
                Third Party Content does not include Your Content. The Third Party Content is, in
                each case, the copyrighted and/or trademarked work of the creator/licensor. You
                agree to only display the Third Party Content on Your computer or other display
                device solely for Your personal use or internal business use. You acknowledge and
                agree that under these terms alone You have no right to download, cache, reproduce,
                modify, display, edit, alter or enhance any of the Third Party Content in any manner
                unless You have permission from the owner of the Third Party Content.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Payments</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                Skylight may allow You to purchase premium services through our Services. We may
                also require that You purchase a subscription to use certain features of the
                Services (a "Premium Membership"). By purchasing a Premium Membership or other
                premium services (each, a "Transaction"), You expressly authorize Skylight (or its
                third-party payment processor) to charge You for such Transaction. Skylight may ask
                You to provide additional information relevant to Your Transaction, including Your
                credit card number, the expiration date of Your credit card and Your email and
                postal addresses for billing and notification (such information, "Payment
                Information"). You represent and warrant that You have the legal right to use all
                payment methods represented by Payment Information that You provide us. You
                acknowledge and agree that we have no liability with respect to any act or omission
                by Your payment provider. When You initiate a Transaction, You authorize us to
                provide Your Payment Information to third parties so we can complete Your
                Transaction and to charge Your payment method for the type of Transaction You have
                selected (plus any applicable taxes and other charges). You may need to provide
                additional information to verify Your identity before completing Your Transaction
                (such information is included within the definition of Payment Information).
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Skylight Plus Membership</Trans>
            </TosSubHeader>
          </SubHeaderIndex>
          <OrderedList>
            <HighlightList>
              <Highlight>
                <Trans>Subscription Fee</Trans>{' '}
              </Highlight>
              <Trans>
                If You purchase a Skylight Plus membership, You will be charged an annual
                subscription fee, plus any applicable taxes and other charges ("Subscription Fee"),
                at the beginning of Your Skylight Plus membership, and at the start of each annual
                billing period thereafter, at the then-current Subscription Fee. Skylight (or its
                third-party payment processor) will automatically charge You annually on the
                anniversary of the commencement of Your Annual Subscription, using the Payment
                Information You have provided until You cancel Your Annual Subscription. If You have
                an Annual Subscription, we will send You a reminder at least three (3) days prior to
                each renewal with the then-current Subscription Fee. We reserve the right to change
                our Subscription Fees. We will provide You advance notice of any increase in the
                Subscription Fees applicable to Your Skylight Plus membership.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Cancellation</Trans>{' '}
              </Highlight>
              <Trans>
                You may cancel Your Skylight Plus membership at any time, contacting our support
                team at
              </Trans>{' '}
              <ALink href="mailto:help@skylightframe.com">help@skylightframe.com</ALink>{' '}
              <Trans>
                and selecting "Cancel Subscription." Skylight will refund to You the unused,
                pre-paid amount of Your Subscription Fee on a pro-rated monthly basis. You will be
                responsible for all Subscription Fees (plus any applicable taxes and other charges)
                incurred until Skylight receives written notice of Your cancelation as set forth
                above.
              </Trans>
            </HighlightList>
          </OrderedList>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Feedback</Trans>{' '}
            </TosSubHeader>
            <Text>
              <Trans>
                You may, during the Term, provide Skylight with oral feedback and/or written
                feedback related to Your use of the Services, Site, or any other Skylight product or
                service, including, but not limited to, a report of any errors which You may
                discover in the foregoing or any related documentation. User Content is expressly
                excluded from Feedback. Such reports, and any other materials, information, ideas,
                concepts, feedback and know-how provided by You to Skylight concerning the Services,
                Site or any other Skylight product or service and any information reported
                automatically through the Services to Skylight ("Feedback") will be the property of
                Skylight. You agree to assign, and hereby assign, all rights, title and interest
                worldwide in the Feedback, and the related intellectual property rights, to Skylight
                and agree to assist Skylight, at Skylight&rsquo; expense, in perfecting and
                enforcing such rights.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Links to Third Party Sites</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                The Site may contain hyperlinks to web sites that are not controlled by Skylight.
                You acknowledge and agree that Skylight is not responsible for and does not endorse
                or accept any responsibility over the content or use of these web sites, including,
                without limitation, the accuracy or reliability of any information, data, opinions,
                advice or statements made on these web sites. You further understand that such third
                party sites and Third Party Services are governed by separate agreements
                accompanying such portions of the Services and You agree to be bound by the terms
                and conditions of such agreements. Your dealings with other entities promoted on or
                through the Services, including payment and delivery of related goods or services,
                and any other terms, conditions, warranties or representations associated with such
                dealings, are solely between You and such other entity. You agree that Skylight will
                not be responsible or liable for any loss or damage of any sort incurred as the
                result of any such dealings or as the result of the promotion of such other entities
                through the Services.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Unauthorized Activities</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                Unauthorized use of the Services or Third Party Content may violate certain laws and
                regulations. You agree to defend, indemnify and hold Skylight and its officers,
                directors, employees, affiliates, agents, licensors, and business partners harmless
                from and against any and all costs, damages, liabilities, and expenses (including
                attorneys&#39; fees and costs of defense) that Skylight or any other indemnified
                party suffers in relation to, arising from, or for the purpose of avoiding, any
                claim or demand from a third party based on Your Content and/or Your use of the
                Services or Third Party Content or the use by any person, using Your user name
                and/or password, that violates any applicable law or regulation, or the copyrights,
                trademark rights or other rights of any third party. Skylight may report to law
                enforcement authorities any actions that may be illegal, and any reports it receives
                of such conduct. When legally required or at Skylight&#39; discretion, Skylight will
                cooperate with law enforcement agencies in any investigation of alleged illegal
                activity on this Site, the Services or on the Internet.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Proprietary Rights</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                Neither this Agreement nor any provision herein transfers ownership or any license
                not expressly granted, from Skylight to You of any Skylight intellectual property
                right of any kind whatsoever including, but not limited to, any Services or the
                Site, and all right, title and interest in and to the foregoing and Skylight&rsquo;
                legally protectable methods, processes, systems, operations, network or customer
                support methodology or technology, or any other technology of any kind whatsoever
                and all intellectual property rights therein will remain solely with Skylight.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Term and Termination</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                Though we&rsquo;d much rather You stay, You can stop using our Services anytime. We
                reserve the right to suspend or end the Services at any time, with or without cause,
                and with or without notice. For example, we may suspend or terminate Your use if You
                are not complying with these Terms, or use the Services in any way that would cause
                us legal liability or disrupt others&rsquo; use of the Services. If we suspend or
                terminate Your use, we will try to let You know in advance and help You retrieve
                data, though there may be some cases (for example, repeatedly or flagrantly
                violating these Terms, a court order, or danger to other users) where we may suspend
                immediately.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Disclaimer of Warranties</Trans>
            </TosSubHeader>
          </SubHeaderIndex>
          <OrderedList>
            <HighlightList>
              <Highlight>
                <Trans>Skylight Hardware Limited Warranty</Trans>{' '}
              </Highlight>
              <Trans>Skylight may offer a warranty for Your physical product.</Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Refund and Replacements Policy</Trans>{' '}
              </Highlight>
              <Trans>
                Within 120 days of purchase: In the event that a customer is not fully satisfied
                with the product or is having technical difficulties with the product, we offer the
                option for a replacement or full refund within 120 days of purchase. If the customer
                prefers a refund, we may request that the product be returned to us within 30 days.
                Our customer service team will provide a pre-paid shipping label so the customer
                does not incur additional costs.
              </Trans>
              <br />
              <Trans>
                After 120 days of purchase: We will consider replacements and refunds on a
                case-by-case basis.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Disclaimer</Trans>{' '}
              </Highlight>
              <Trans>
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, ALL IMPLIED WARRANTIES OF MERCHANTABILITY OR
                FITNESS FOR A PARTICULAR PURPOSE WITH RESPECT TO THE PRODUCTS THAT CANNOT BE
                DISCLAIMED ARE LIMITED TO THE DURATION OF THE APPLICABLE EXPRESS WARRANTY. ALL OTHER
                EXPRESS OR IMPLIED CONDITIONS, REPRESENTATIONS AND WARRANTIES, INCLUDING ANY IMPLIED
                WARRANTY OF NON-INFRINGEMENT, ARE DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
                Some jurisdictions do not allow limitations on how long an implied warranty lasts,
                so the above limitation may not apply to You. This warranty gives You specific legal
                rights, and You may also have other rights which vary by jurisdiction and which are
                not varied by this clause to the extent that doing so would breach such applicable
                law.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Disclaimer of Warranty for Services</Trans>{' '}
              </Highlight>
              <Trans>
                Skylight will use commercially reasonable efforts to correct any discovered defects
                in the Services. However, to the maximum extent permitted by applicable law, Your
                access to and use of the Services is at Your own risk. We are not responsible for
                the content provided by, or the conduct of, any user and You bear the entire risk of
                using the Services and any interactions with other users. Our Services are provided
                on an "AS IS" and "AS AVAILABLE" basis and, to the maximum extent permitted by
                applicable law, we do not represent, warrant, or guarantee that the Services will be
                provided uninterrupted, error-free, virus-free, or that defects will be corrected.
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES
                REGARDING THE SERVICES, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING THE
                WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE, OR
                NON-INFRINGEMENT. If applicable law requires any warranties with respect to our
                Services, all such warranties are limited in duration to the longer of thirty (30)
                days from the date of Your first use or the minimum duration allowed by law.
              </Trans>
            </HighlightList>
          </OrderedList>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Limitation of Liability</Trans>
            </TosSubHeader>
          </SubHeaderIndex>
          <OrderedList>
            <HighlightList>
              <Highlight>
                <Trans>Exclusion of Consequential Damages and Limit of Liability</Trans>{' '}
              </Highlight>
              <Trans>
                EXCEPT TO THE EXTENT REQUIRED BY LAW, SKYLIGHT WILL NOT BE RESPONSIBLE FOR ANY LOST
                PROFITS, REVENUES, OR DATA, COST OF SUBSTITUTE GOODS OR SERVICES, OR ANY INDIRECT,
                SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, HOWEVER CAUSED AND
                REGARDLESS OF THE THEORY OF LIABILITY, RELATED TO OR ARISING OUT OF THESE TERMS OR
                YOUR USE OF THE PRODUCTS, OR SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT PERMITTED BY LAW, OUR TOTAL LIABILITY FOR
                ANY CLAIM RELATED TO OR ARISING OUT OF THESE TERMS OR YOUR USE OF THE PRODUCTS OR
                SERVICES, IS LIMITED TO (A) THE AMOUNT YOU HAVE PAID TO SKYLIGHT FOR THE PRODUCT
                GIVING RISE TO THE CLAIM, (B) THE AMOUNT YOU HAVE PAID TO SKYLIGHT FOR YOUR SKYLIGHT
                PLUS MEMBERSHIP GIVING RISE TO THE CLAIM IN THE IMMEDIATE TWELVE (12) MONTHS
                PRECEDING THE DATE THE CLAIM AROSE, OR (C) 159 DOLLARS ($159) IF YOU HAVE NOT MADE
                ANY PAYMENT TO SKYLIGHT IN SUCH TWELVE MONTH PERIOD, AS APPLICABLE.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Allocation of Risk</Trans>{' '}
              </Highlight>
              <Trans>
                THE PROVISIONS OF THIS AGREEMENT ALLOCATE THE RISKS UNDER THIS AGREEMENT BETWEEN THE
                PARTIES AND ARE AN INTRINSIC PART OF THE BARGAIN BETWEEN THE PARTIES. THE FEES
                PROVIDED FOR IN THIS AGREEMENT REFLECT THIS ALLOCATION OF RISKS AND THE LIMITATION
                OF LIABILITY AND SUCH LIMITATION SHALL APPLY NOTWITHSTANDING A FAILURE OF THE
                ESSENTIAL PURPOSE OF ANY LIMITED REMEDY AND TO THE FULLEST EXTENT PERMITTED BY LAW.
              </Trans>
            </HighlightList>
          </OrderedList>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Local Laws; Export Control</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                Services and/or Third Party Content may not be appropriate or available for use
                outside of the United States of America. The Service is controlled or operated (or
                both) from the United States, and is not intended to subject Skylight to any
                non-U.S. jurisdiction or law. The Service may not be appropriate or available for
                use in some non-U.S. jurisdictions. Any use of the Service is at your own risk, and
                you must comply with all applicable laws, rules and regulations in doing so. We may
                limit the Service&rsquo;s availability at any time, in whole or in part, to any
                person, geographic area or jurisdiction that we choose.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Dispute Resolution and Binding Arbitration Provision.</Trans>
            </TosSubHeader>
          </SubHeaderIndex>
          <OrderedList>
            <HighlightList>
              <Highlight>
                <Trans>Informal Efforts to Resolve Dispute</Trans>{' '}
              </Highlight>
              <Trans>
                If a dispute arises between You and Skylight, You should first attempt to resolve it
                by contacting our Customer Service Center at
              </Trans>{' '}
              <ALink href="mailto:help@skylightframe.com">help@skylightframe.com</ALink>{' '}
              <Trans>
                or by sending the details of Your complaint, including Your contact information for
                a response, to the address or email address listed below. We will attempt in good
                faith to resolve all Claims submitted this way within fifteen (15) days of receipt.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Agreement to Arbitrate;</Trans>{' '}
              </Highlight>
              <Trans>
                Right to Opt Out. If informal efforts to resolve Claims fail or are not used, You
                agree that any and all Claims will be resolved exclusively by binding arbitration as
                described herein, except that: (i) You may assert Claims in a small claims court in
                the United States if Your Claims meet the court's jurisdictional requirements; and
                (ii) either party may pursue Claims and relief in a court of competent jurisdiction
                regarding the validity and/or infringement of a party's intellectual property
                rights.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Process</Trans>{' '}
              </Highlight>
              <Trans>
                There is no judge or jury in arbitration, and court review of an arbitration award
                is very limited. However, an arbitrator can award to You on an individual basis the
                same damages and forms of relief as a court could (including injunctive and
                declaratory relief as well as statutory damages), and must follow the law and terms
                of this Agreement as a court would. Any arbitration under this Agreement will take
                place on an individual basis; class arbitrations and class actions and private
                attorney general actions are not permitted.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Opt Out</Trans>{' '}
              </Highlight>
              <Trans>
                IF YOU DO NOT WISH TO BE BOUND BY THIS ARBITRATION PROVISION, YOU MUST NOTIFY
                SKYLIGHT IN WRITING WITHIN THIRTY (30) DAYS FROM THE DATE THAT YOU FIRST ACCEPT OR
                HAVE ACCESS TO THIS AGREEMENT BY MAILING OR FAXING AN OPT-OUT REQUEST TO OUR
                CUSTOMER SERVICE CENTER LISTED BELOW. YOUR WRITTEN NOTIFICATION MUST INCLUDE YOUR
                NAME, ADDRESS, THE EMAIL ADDRESS YOU USED TO REGISTER WITH SKYLIGHT, AND A CLEAR
                STATEMENT THAT YOU DO NOT WISH TO RESOLVE DISPUTES WITH US THROUGH ARBITRATION. YOUR
                DECISION TO OPT OUT OF THIS ARBITRATION PROVISION WILL HAVE NO ADVERSE EFFECT ON
                YOUR RELATIONSHIP WITH US OR THE DELIVERY OF PRODUCTS OR SERVICES TO YOU BY US. IF
                YOU HAVE PREVIOUSLY NOTIFIED US OF YOUR DECISION TO OPT OUT OF ARBITRATION, YOU DO
                NOT NEED TO DO SO AGAIN.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Arbitration Fees</Trans>{' '}
              </Highlight>
              <Trans>
                The allocation and payment of all filing, administration and arbitrator fees will be
                governed by the JAMS's rules which limit the amount a consumer is required to pay.
                If the arbitrator determines that Your Claims are not frivolous applying the
                standards of the Federal Rules of Civil Procedure, we agree to reimburse You the
                amount of all filing, administration and arbitrator fees You are required to pay for
                the arbitration.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Arbitration Rules</Trans>{' '}
              </Highlight>
              <Trans>
                The arbitration will be conducted by the American Arbitration Association ("AAA")
                under its rules if You are a resident of the United States; if Your use of the
                Products has been principally for personal or household use, the AAA's procedures
                for consumer-related disputes including the minimum fairness standards will also
                apply. If You are a resident of a country other than the United States, the
                arbitration will be conducted by the AAA in San Francisco, California, under its
                rules for international arbitration, and You and we agree to submit to the personal
                jurisdiction of the U.S. federal court in San Francisco, California in order to
                compel arbitration, to stay proceedings pending arbitration, or to confirm, modify,
                vacate or enter judgment on the award entered by the arbitrator. In the event of a
                conflict or inconsistency between the applicable arbitration rules and this
                Arbitration Provision, this Arbitration Provision shall govern and control.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Procedure</Trans>{' '}
              </Highlight>
              <Trans>
                The arbitration will be conducted in the English language by a single arbitrator who
                is an attorney-at-law with at least fifteen years' experience in consumer and
                technology transactions and who is also a member of the AAA roster of arbitrators.
                If You and we can't agree on a mutually acceptable arbitrator within fifteen (15)
                days after the arbitration is initiated, then the JAMS will pick a neutral
                arbitrator who meets the qualifications. The AAA rules are available at
              </Trans>{' '}
              <ALink href="https://www.adr.org">https://www.adr.org</ALink>
              <Trans>, or by calling 800-778-7879 from inside the United States.</Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Initiating Arbitration</Trans>{' '}
              </Highlight>
              <Trans>
                To begin an arbitration proceeding, You must follow the procedures specified by the
                applicable AAA rules as described on their website at
              </Trans>{' '}
              <ALink href="https://www.adr.org">https://www.adr.org</ALink>.
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Time Restriction</Trans>{' '}
              </Highlight>
              <Trans>
                YOU MUST FILE A COMPLAINT WITH AAA OR A PERMITTED COURT WITHIN ONE (1) YEAR OF THE
                DATE OF THE OCCURRENCE OF THE EVENT OR FACTS GIVING RISE TO A CLAIM, OR YOU WAIVE
                THE RIGHT TO PURSUE ANY CLAIM BASED UPON SUCH EVENT, FACTS OR DISPUTE.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Arbitration Process</Trans>{' '}
              </Highlight>
              <Trans>
                Because appearing in person for arbitration can be unduly burdensome in the
                circumstances, arbitration under this Arbitration Provision shall not require any
                personal appearance by the parties or witnesses unless mutually agreed. Either or
                both parties may participate by written submissions, telephone calls, or other means
                of remote communication as allowed by the arbitrator. The arbitration proceedings
                will be conducted in the English language at a location designated by the AAA that
                is the most convenient for You.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>No Class Claims</Trans>{' '}
              </Highlight>
              <Trans>
                The arbitration can only decide Claim(s) between You and us, and may not consolidate
                or join the claims of other persons that may have similar claims. There shall be no
                pre-arbitration discovery except as provided for in the applicable AAA rules. The
                arbitrator will honor claims of privilege recognized by law and will take reasonable
                steps to protect customer account information and other confidential or proprietary
                information.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Governing Law</Trans>{' '}
              </Highlight>
              <Trans>
                In conducting the arbitration proceeding, the arbitrator will apply the law of the
                State of California (without regard to its conflicts of law provisions) including
                U.S. federal law for matters covered by federal law (e.g. the Federal Arbitration
                Act). The confidentiality provisions of this Agreement will be enforceable under the
                provisions of the California Uniform Trade Secrets Act, California Civil Code
                Section 3426, as amended. At the request of any party, the arbitrator shall provide
                a brief written explanation of the basis for the decision and award. Judgment upon
                the award rendered by the arbitrator may be entered in any court having
                jurisdiction. The arbitrator&rsquo;s decision will be final and binding on the
                parties except for any right to appeal under the AAA rules or the Federal
                Arbitration Act.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Recovery and Attorneys' Fees</Trans>{' '}
              </Highlight>
              <Trans>
                If the arbitrator rules in Your favor on the merits of any Claim You bring against
                us and issues You an award that is greater in monetary value than our last written
                settlement offer made to You before written submissions are made to the arbitrator,
                then we will (i) pay You 150% of Your arbitration damages award, up to $1,000 over
                and above Your damages award; and (ii) pay Your attorneys, if any, the amount of
                attorneys' fees, and reimburse any expenses (including expert witness fees and
                costs) that You or Your attorney reasonably incurred for investigating, preparing,
                and pursuing Your Claim in arbitration. The arbitrator may make rulings and resolve
                disputes as to the payment and reimbursement of such fees, expenses, and the
                alternative payment and the attorney premium at any time during the proceeding and
                upon request from either party made within 14 days of the arbitrator's ruling on the
                merits. The right to attorneys' fees and expenses discussed above supplements any
                right to attorneys' fees and expenses You may have under applicable law, although
                You may not recover duplicative awards of attorneys' fees or costs. If Your use of
                the Products was principally for personal or household use, neither party will be
                entitled to any award of punitive or special damages and Skylight waives any right
                it may have to seek an award of attorneys' fees and expenses from You in connection
                with any arbitration of Claims between us.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Confidentiality</Trans>{' '}
              </Highlight>
              <Trans>
                You and we shall keep confidential any information exchanged during the arbitration
                as well as the decision of the arbitrator made with respect to any Claim(s)
                arbitrated under this Arbitration Provision and, with the exception of disclosure to
                Your or our attorneys, accountants, auditors, and other legal or financial advisors,
                neither party shall disclose such information or decision to any other person unless
                required to do so by law.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Continuing Obligation to Arbitrate; Severability</Trans>{' '}
              </Highlight>
              <Trans>
                This Arbitration Provision shall survive termination of Your access to or use of any
                Products and related agreements. If any portion of this Arbitration Provision is
                deemed invalid or unenforceable at law, such invalid or unenforceable provision will
                be interpreted, construed or reformed to the extent required to make it valid and
                enforceable, and this shall not invalidate the remaining portions of this
                Arbitration Provision.
              </Trans>
            </HighlightList>
            <HighlightList>
              <Highlight>
                <Trans>Skylight Customer Service Center Address</Trans>{' '}
              </Highlight>
              <span>
                <br />
                Glimpse LLC (dba Skylight)
                <br />
                ATTN: LEGAL/ARBITRATION
                <br />
                101A Clay St #144
                <br />
                San Francisco, California 94111 <br />
                <ALink href="mailto:help@skylightframe.com">help@skylightframe.com</ALink>
              </span>
            </HighlightList>
          </OrderedList>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Force Majeure</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                Neither party will be liable to the other for any breach of this Agreement which
                arises because of any circumstances which the defaulting party cannot reasonably be
                expected to control (which shall include but not be limited to, any act of god,
                fire, casualty, flood, war, labor disputes, governmental action, failure of public
                services, interruption or failure of the Internet or of any network,
                telecommunications, power supply or infrastructure, or any provider of any of the
                foregoing but, for the avoidance of doubt, shall not include shortage or lack of
                available funds, provided it promptly notifies the other party of such
                circumstances. If performance has been interfered with, hindered, delayed or
                prevented for a period in excess of ninety (90) days, the party whose performance is
                not affected may terminate this Agreement immediately by written notice to the other
                party.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>General</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                These Terms constitute the entire and exclusive agreement between You and Skylight
                with respect to the Services, and supersede and replace any other agreements, terms
                and conditions applicable to the Services. These Terms create no third party
                beneficiary rights. Skylight's failure to enforce a provision is not a waiver of its
                right to do so later. If a provision is found unenforceable the remaining provisions
                of the Agreement will remain in full effect and an enforceable term will be
                substituted reflecting our intent as closely as possible. You may not assign any of
                Your rights in these Terms, and any such attempt is void, but Skylight may assign
                its rights to any of its affiliates or subsidiaries, or to any successor in interest
                of any business associated with the Services. Skylight and You are not legal
                partners or agents; instead, our relationship is that of independent contractors.
              </Trans>
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Electronic Communications</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                The information communicated on this Website constitutes an electronic
                communication. When You communicate with Skylight through the Website or other forms
                of electronic media, such as e-mail, You are communicating with Skylight
                electronically. You agree that Skylight, on behalf of itself and others who may be
                involved with delivering the Services (as applicable), may communicate
                electronically by e-mail and/or may make communications available to You by posting
                them on the Website, and that such communications, as well as notices, disclosures,
                agreements and other communications that we provide to You electronically, are
                equivalent to communications in writing and shall have the same force and effect as
                if they were in writing and signed by Skylight. Notices and communications to
                Skylight must be sent to the applicable address given in these Terms or to;
              </Trans>{' '}
              <ALink href="mailto:help@skylightframe.com"> help@skylightframe.com</ALink>
              <Trans i18nKey="gesendet_werden" />.
            </Text>
          </SubHeaderIndex>
          <SubHeaderIndex>
            <TosSubHeader>
              <Trans>Notice for California Users</Trans>
            </TosSubHeader>
            <Text>
              <Trans>
                Under California Civil Code Section 1789.3, California users of the Site are
                entitled to the following specific consumer rights notice: The headquarters of
                Glimpse LLC, d/b/a/ Skylight is currently located at 101A Clay St #144, San
                Francisco, California 94111, USA, phone (415) 233-6761. The charges for the Services
                are specified on the Site. If You have a complaint regarding the Services or want to
                request a paper copy of these Terms, please contact Skylight by writing to the
                address above, or by e-mail at
              </Trans>{' '}
              <ALink href="mailto:help@skylightframe.com">help@skylightframe.com</ALink>
              <Trans>
                , or by calling (415) 233-6761. The Consumer Information Center of the Department of
                Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite
                N-112, Sacramento, CA 95384, or by telephone at (916) 445-1254 or (800) 952-5210.
              </Trans>
            </Text>
          </SubHeaderIndex>
        </OrderedList>
      </Column>
    </Container>
  </>
)

export default withTranslation()(Tos)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "tos"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
